<template>
    <gk-searchbar
        v-model:form="searchForm"
        :config="searchConfig"
        :fold-display-number="3"
        :foldable="true"
        :options="searchOptions"
        @reset="getDataList"
        @search="getDataList"
    />
    <div class="common-list">
        <div class="button-wapper">
            <el-button :disabled="selectionIds.length < 1" type="primary" @click="batchAngelLockDialog.show = true">
                批量更改角度锁定
            </el-button>
            <el-button :disabled="selectionIds.length < 1" type="primary" @click="openBatchOffsetDialog('angle')">
                批量更新角度偏差
            </el-button>
            <el-button :disabled="selectionIds.length < 1" type="primary" @click="openBatchOffsetDialog('speed')">
                批量更新加速度偏差
            </el-button>
            <el-button type="success" @click="getDataList">刷新数据</el-button>
            <el-button type="danger" @click="onDelete()">删除</el-button>
        </div>
        <div class="table-wrapper">
            <el-table :data="dataList" border stripe @selection-change="changeSelectionIds">
                <el-table-column type="selection" width="55" />
                <el-table-column label="ID" prop="id" />
                <el-table-column label="设备编码" prop="equipment_sn" />
                <el-table-column label="三轴角度" prop="triaxial_angle" />
                <el-table-column label="角度报警阈值" prop="triaxial_angle_alarm" />
                <el-table-column label="角度锁定" prop="triaxial_angle_lock" />
                <el-table-column label="三轴加速度" prop="triaxial_speed" />
                <el-table-column label="加速度报警阈值" prop="triaxial_speed_alarm" />
                <el-table-column label="电池电压" prop="voltage" />
                <el-table-column label="角度锁定状态">
                    <template #default="scope">
                        <el-switch
                            v-model="scope.row.triaxial_angle_lock_status"
                            :active-value="1"
                            :inactive-value="0"
                            active-color="#ff0000"
                            @click="angleLockChange(scope.row.id, scope.row.triaxial_angle_lock_status)"
                        />
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="251">
                    <template #default="scope">
                        <el-button plain size="mini" type="primary" @click="openOffsetDialog(scope.row.id, 'angle')">
                            更新角度偏差
                        </el-button>
                        <el-button plain size="mini" type="primary" @click="openOffsetDialog(scope.row.id, 'speed')">
                            更新加速度偏差
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pagination-wrapper">
            <gk-pagination
                v-model:currentPage="dataPagination.current"
                v-model:pageSize="dataPagination.size"
                :total="dataPagination.total"
                @change="getDataList"
            />
        </div>
    </div>
    <!--批量角度锁定弹窗-->
    <el-dialog
        v-model="batchAngelLockDialog.show"
        :close-on-click-modal="false"
        title="批量修改角度锁定状态"
        width="300px"
        custom-class="batch-lock-dialog"
    >
        <div>
            <span>角度锁定状态：</span>
            <el-switch
                size="large"
                v-model="batchAngelLockDialog.status"
                :active-value="1"
                :inactive-value="0"
                active-color="#ff0000"
            />
        </div>
        <template #footer>
            <el-button @click="batchAngelLockDialog.show = false">取消</el-button>
            <el-button type="primary" @click="batchAngleLock">提交</el-button>
        </template>
    </el-dialog>
    <!--更新偏差弹窗-->
    <el-dialog
        v-model="offsetDialog.show"
        :close-on-click-modal="false"
        :title="offsetDialog.type === 'angle' ? '更新角度偏差' : '更新加速度偏差'"
        width="600px"
        custom-class="offset-dialog"
    >
        <el-form>
            <el-form-item v-if="offsetDialog.type === 'angle'" label="角度偏差：">
                <el-input v-model="offsetDialog.alarm_value" placeholder="请输入角度偏差" />
            </el-form-item>
            <el-form-item v-if="offsetDialog.type === 'speed'" label="加速度偏差：">
                <el-input v-model="offsetDialog.alarm_value" placeholder="请输入加速度偏差" />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="offsetDialog.show = false">取消</el-button>
            <el-button type="primary" @click="updateOffset">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";
import useCommonList from "../../../../hooks/useCommonList";
import {
    ANGLE_LOCK_BATCH,
    ANGLE_LOCK_SINGLE,
    BATCH_UPDATE_OFFSET,
    GET_EQUIPMENT_TERMINAL_LIST,
    UPDATE_OFFSET
} from "@/api/equipment";
import { ElMessage } from "element-plus";

let { dataPagination, dataList, selectionIds, changeSelectionIds } = useCommonList();

onMounted(() => {
    getDataList();
    setInterval(() => {
        getDataList();
    }, 1000 * 60 * 15);
});

// 搜索项
let searchConfig = ref([]);

let searchOptions = ref({});

let searchForm = ref({
    name: null
});

// 获取数据
let getDataList = () => {
    GET_EQUIPMENT_TERMINAL_LIST({
        ...searchForm.value,
        page: dataPagination.value.current,
        limit: dataPagination.value.size
    }).then((res) => {
        dataList.value = res.data.data.list;
        dataPagination.value.total = res.data.data.mate.total;
        console.log(res.data.data);
    });
};

// 角度锁定开关
let angleLockChange = (id, status) => {
    ANGLE_LOCK_SINGLE({ id, status }).then((res) => {
        if (res.data.code === 200) {
            ElMessage.success("更新成功");
        }
    });
};

// 批量角度锁定
let batchAngelLockDialog = ref({
    show: false,
    status: 1
});

let batchAngleLock = () => {
    ANGLE_LOCK_BATCH({
        ids: selectionIds.value,
        status: batchAngelLockDialog.value.status
    }).then((res) => {
        if (res.data.code === 200) {
            ElMessage.success("更新成功");
            batchAngelLockDialog.value.show = false;
            getDataList();
        }
    });
    console.log(selectionIds.value);
};

// 偏差弹窗
let offsetDialog = ref({
    show: false,
    isBatch: false,
    id: null,
    type: null,
    alarm_value: null
});

let openOffsetDialog = (id, type) => {
    offsetDialog.value = {
        show: true,
        isBatch: false,
        id: id,
        type: type,
        alarm_value: null
    };
};

let openBatchOffsetDialog = (type) => {
    offsetDialog.value = {
        show: true,
        isBatch: true,
        id: null,
        type: type,
        alarm_value: null
    };
};

let updateOffset = () => {
    if (offsetDialog.value.isBatch) {
        BATCH_UPDATE_OFFSET({
            type: offsetDialog.value.type,
            ids: selectionIds.value,
            alarm_value: offsetDialog.value.alarm_value
        }).then((res) => {
            if (res.data.code === 200) {
                ElMessage.success("更新成功");
                offsetDialog.value.show = false;
                getDataList();
            }
        });
    } else {
        UPDATE_OFFSET({
            type: offsetDialog.value.type,
            id: offsetDialog.value.id,
            alarm_value: offsetDialog.value.alarm_value
        }).then((res) => {
            if (res.data.code === 200) {
                ElMessage.success("更新成功");
                offsetDialog.value.show = false;
                getDataList();
            }
        });
    }
};

let onDelete = (id = null) => {
    if (id) {
    } else {
        if (selectionIds.value.length < 1) {
            ElMessage.error("请先选择删除内容!");
            return false;
        }
        ElMessageBox.confirm("确认删除选中内容？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning"
        }).then(() => {
            BATCH_DELETE_EQUIPMENT_TERMINAL({ ids: selectionIds.value }).then((res) => {
                if (res.data.code === 200) {
                    getDataList();
                    ElMessage.success("删除成功");
                }
            });
        });
    }
};
</script>
